import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge } from 'primereact/badge';


// config file

const Payments = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  const loginCredentials = JSON.parse(localStorage.getItem('loginCredentials'));
  const isAdmin = loginCredentials?.role === 'Admin';

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);


  useEffect(() => {
    if(searchParams.get('success') === "false") {
      showToasterMessage("Payment Failed", 'error');
      navigate("/payments")
    }
    else if (searchParams.get('success')) {
      showToasterMessage('Payment success', 'success');
      navigate('/payments');
    }
    let screenPermissions = RolePermissions.screenPermissions("Payments");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const customPayColumn = (data) => {
    const { item, column } = data;

    const payAmount = (e) => {
      const { setIsLoading } = dataTableRef.current;
      if (setIsLoading) {
        setIsLoading(true);
      }
      console.log('item.body', item.body);
      fetchMethodRequest('POST', apiCalls.sendRequest, item.body).then(res => {
        if (res?.link) {
          window.location.href = res.link;
        }
        if (setIsLoading) {
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        }
      })
    }
    if (item.status === 'Pending') {
      return <Badge className="rounded-pill" onClick={payAmount} value="Pay" severity="danger"></Badge>
    }
  }

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "Check Box",
      //   label: "Check Box",
      //   fieldType: "multiple",
      //   header: "",
      //   selectionMode: "multiple",
      //   show: true,
      //   mobile: true,
      //   displayInSettings: true,
      //   displayinlist: "false",
      //   displayinaddForm: "false",
      //   displayineditForm: "false",
      //   globalSearchField: "false",
      //   isFieldRequired: "false",
      //   tDisplay: true,
      // },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      // {
      //   name: "patient",
      //   type: "relateAutoComplete",
      //   placeholder: "Name",
      //   label: "Name",
      //   width: "150px",
      //   addFormOrder: 7,
      //   editFormOrder: 7,
      //   header: "Name",
      //   derivedValue: "patient=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   isClickable: false,
      //   filter: false,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "patient",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "true",
      //   required: true,
      //   displayOptionsInActions: false,
      //   globalSearchField: "true",
      //   controllerId: "Patients",
      //   searchField: "name",
      //   fieldType: "relateAutoComplete",
      //   populteFields: [],
      //   displayFields: ["name"],
      //   controllerName: "Patients",
      //   searchApi: "patients",
      //   isMultiple: false,
      //   textAlign: "Center",
      //   show: true,
      //   field: "patient",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      //   tDisplay: true,
      // },
      {
        name: "amount",
        fieldName: "amount",
        type: "number",
        placeholder: "Amount",
        value: "",
        label: "Amount",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Amount",
        derivedValue: "amount=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: false,
        id: "amount",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "amount",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },

      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.fullDateFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "payedDate",
        type: "date",
        placeholder: "Paid date",
        label: "Paid date",
        width: 90,
        header: "Paid date",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        globalSearchField: "true",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.fullDateFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "payedDate",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        type: 'dropDown',
        textAlign: "center",
        width: 80,
        field: "status",
        label: "Status",
        mobile: true,
        fieldType: "dropDown",
        header: "Status",
        filter: false,
        displayInSettings: true,

        sortable: false,
        options: [
          { value: "Completed", color: "success" },
          { value: "Failed", color: "danger"},
          { value: "Pending", color: "warning" }
        ],
        filterElement: [
          { label: "All", value: null },
          { label: "Completed", value: "Completed" },
          { label: "Pending", value: "Pending" },
        ]
      },
      {
        name: "pay",
        fieldName: "Pay",
        type: "text",
        customColumn: customPayColumn,
        placeholder: "Pay",
        value: "",
        label: "Pay",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Pay",
        derivedValue: "Pay=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "Pay",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "pay",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "customColumn",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        displayinregisterForm: false,
        globalSearchField: "true",
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "amount",
        fieldName: "amount",
        type: "number",
        placeholder: "Amount",
        value: "",
        label: "Amount",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "amount=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "amount",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "Pay",
        fieldName: "Pay",
        type: "text",
        placeholder: "Pay",
        value: "",
        label: "Pay",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "Pay=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "Pay",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        displayinregisterForm: false,
        globalSearchField: "true",
        show: true,
        field: "name",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "amount",
        fieldName: "amount",
        type: "number",
        placeholder: "Amount",
        value: "",
        label: "Amount",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Amount",
        derivedValue: "amount=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "amount",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "amount",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "Pay",
        fieldName: "Pay",
        type: "text",
        placeholder: "Pay",
        value: "",
        label: "Pay",
        width: "120px",
        maxFileSize: "",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Pay",
        derivedValue: "Pay=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "Pay",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        textAlign: "center",
        show: true,
        field: "Pay",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.payments}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    // {
    //   name: "Delete",
    //   options: [
    //     {
    //       label: "Delete",
    //       value: "Delete",
    //       show: rolePermission?.actions?.includes("Delete") ? true : false,
    //       multiple: true,
    //     },
    //   ],
    // },
  ];

  const rowClassName = (item) => {
    if (item.status === 'Pending') {
      return 'pending_payment';
    } 
    else if( item.status === "Failed") return "failed_payment";
    else if (item.status === 'Completed') {
      return 'completed_payment';
    }
  }

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          rowClassName={rowClassName}
          viewRequired={isEditPermission}
          // globalSearch={'Display Name/Email'}
          // type='Payments'
          // apiUrl={apiCalls.Payments}
          // exportRequried={true}
          printRequried={false}
          actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={false}
          filterRequired={false}
          gridRequried={false}
          exportToCsv={false}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.payments}
          displayName="Payments"
          type="Payments"
          routeTo={'payments'}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={'requestPayments'}
          apiUrl={apiCalls.payments}
          selectedId={params.id}
          isDisablePaginator={!isAdmin}
          disableClearFilter={!isAdmin}
          disableSaveFilter={!isAdmin}
          disableActionDropdown={!isAdmin}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="payments"
          apiUrl={apiCalls.payments}
        />
      ) : null}
    </span>
  );
};

export default Payments;
